<template>
  <article class="view-newsletter">
    <div class="site-wrapper rythm-v">
      <header class="page-header">
        <h1>Newsletter CrossDoc</h1>
      </header>

      <section
        class="section--subscribe card shadow rythm-v"
        v-if="is_subscribing"
      >
        <header>
          <h2>Abonnement à la newsletter</h2>
        </header>

        <div>
          <p>Merci de patienter, nous procédons à votre inscription…</p>
        </div>
      </section>

      <section
        class="section--subscribe card shadow rythm-v"
        v-if="is_unsubscribing"
      >
        <header>
          <h2>Désabonnement de la newsletter</h2>
        </header>

        <div>
          <p>Merci de patienter, nous procédons à votre désinscription…</p>
        </div>
      </section>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@import "src/scss/newsletter.scss";
</style>

<script>
import { HTTP } from "../http-common";
import { EventBus } from "../event-bus.js";
import { log } from "logrocket";

export default {
  name: "newsletter",

  props: ["validationToken"],

  data: function() {
    return {
      user: null,

      // Subscription
      is_subscribing: false,
      is_subscribed: false,

      // Unsubscription
      is_unsubscribing: false,
      is_unsubscribed: false,
    };
  },

  mounted() {
    // Current user data (if logged-in)
    this.user = this.$store.state.userData;

    // Check validationToken

    // Confirm Subscription
    if (
      this.$route.path.includes("confirm-subscription") &&
      this.validationToken
    ) {
      this.is_subscribing = true;
      this.is_subscribed = false;

      this.newsletterSubscribe();
    }

    // Confirm Unsubscription
    if (
      this.$route.path.includes("confirm-unsubscription") &&
      this.validationToken
    ) {
      this.is_unsubscribing = true;
      this.is_unsubscribed = false;

      this.newsletterUnsubscribe();
    }
  },

  methods: {
    // Subscribe
    newsletterSubscribe: function() {
      this.$store
        .dispatch("NEWSLETTER_CONFIRM_SUBSCRIPTION", {
          validationToken: this.validationToken,
        })
        .then(() => {
          EventBus.$emit("trackEvent", "Newsletter", "Subscribed");

          this.is_subscribed = true;

          this.$toasted.global.appSuccess({
            message: "<strong>Vous êtes abonné à la newsletter !</strong>",
          });
        })
        .catch((error) => {
          let msg =
            "Une erreur est survenue lors de votre inscription à la newsletter.";

          if (error.status === 422) {
            msg = "L'adresse e-mail est invalide&nbsp;!";
          }

          if (error.status === 403) {
            msg = "Ce lien de validation a expiré ou est invalide&nbsp;!";
          }

          EventBus.$emit("trackEvent", "Newsletter", "Subscribe error");

          return this.$toasted.global.appError({
            message: `<strong>${msg}</strong>`,
          });
        });
    },

    // Unsubscribe
    newsletterUnsubscribe: function() {
      this.$store
        .dispatch("NEWSLETTER_CONFIRM_UNSUBSCRIPTION", {
          validationToken: this.validationToken,
        })
        .then(() => {
          EventBus.$emit("trackEvent", "Newsletter", "Unsubscribed");
          this.is_unsubscribed = true;

          this.$toasted.global.appSuccess({
            message:
              "<strong>Vous n’êtes plus abonné à la newsletter.</strong>",
          });
        })
        .catch((error) => {
          let msg =
            "Une erreur est survenue lors de votre désinscription de la newsletter.";

          if (error.status === 422) {
            msg = "L'adresse e-mail est invalide&nbsp;!";
          }

          if (error.status === 403) {
            msg = "Ce lien de validation a expiré ou est invalide&nbsp;!";
          }

          EventBus.$emit("trackEvent", "Newsletter", "Unsubscribe error");

          return this.$toasted.global.appError({
            message: `<strong>${msg}</strong>`,
          });
        });
    },
  },
};
</script>
